exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-deal-page-template-js": () => import("./../../../src/templates/deal-page-template.js" /* webpackChunkName: "component---src-templates-deal-page-template-js" */),
  "component---src-templates-post-page-template-js": () => import("./../../../src/templates/post-page-template.js" /* webpackChunkName: "component---src-templates-post-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/product-page-template.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */)
}

