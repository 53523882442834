import { createTheme } from '@mui/material/styles';

const getMUITheme = (isDarkMode) => {
  return createTheme({
    palette: {
      type: isDarkMode ? 'dark' : 'light',
      text: {
        primary: isDarkMode ? '#F2F2F2' : '#240404',
      },
      background: {
        default: isDarkMode ? '#240404' : '#F2F2F2',
      },
      primary: {
        main: '#850A0A',
      },
      green: {
        main: '#AFCB69',
      },
      secondary: {
        main: isDarkMode ? '#0a0202' : '#f0b4b4',
      },
      accent: {
        main: isDarkMode ? '#f08484' : '#c31818',
      },
    },
  });
};

export default getMUITheme;